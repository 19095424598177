
import { Options, Vue } from "vue-class-component";
import MIcon from "@/components/MIcon.vue";
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/form/MButton.vue";
import MTextField from "@/components/form/MTextField.vue";
import MTextArea from "@/components/form/MTextArea.vue";
import { Student } from "@/entities/student";
import store from "@/store";
import "firebase/firestore";
import "firebase/functions";
import {
  Unit,
  Reflection,
  reflectionCollectionKey,
  convertToReflection
} from "@/entities/reflection";
import MSLineChart from "@/components/analyze/MSLineChart.vue";
import dayjs from "dayjs";
import "dayjs/locale/ja";
import firebase from "firebase/app";
dayjs.locale("ja");

@Options({
  components: {
    MIcon,
    MBaseModal,
    MButton,
    MTextField,
    MSLineChart,
    MTextArea
  },
  emits: ["close", "aiReflectionUpdate"],
  props: {
    student: Object,
    unit: Object,
    canEdit: Boolean
  }
})
export default class MsEditModal extends Vue {
  student!: Student;
  unit: Unit | null = null;
  reflections: Reflection[] = [];
  datasets: {
    label: string;
    data: number[];
    borderColor: string;
    borderWidth: number;
    tension: number;
  }[] = [];
  labels: string[] = [];
  min = 0;
  max = 100;
  canEdit = false;
  unitName = "";
  graphTitle = "";
  secondRowTitle = "";
  thirdRowTitle = "";
  status:
    | "display"
    | "recordInput"
    | "unitReflectionInput"
    | "recordEdit"
    | "unitReflectionEdit" = "display";

  close() {
    this.$emit("close");
  }

  formatDate(uid: string): string {
    const unix = this.getUnixFromUid(uid);
    return dayjs.unix(unix).format("MM/DD(ddd)");
  }

  async getReflections() {
    if (!this.unit) return [];
    const snapshot = await this.unit.ref
      .collection(reflectionCollectionKey)
      .get();
    if (snapshot.empty) return [];
    return snapshot.docs
      .filter(doc => doc.exists && doc.data())
      .map(doc => convertToReflection(doc.data(), doc.ref));
  }

  isFutureDate(dateStr: string): boolean {
    const date = dayjs(dateStr);
    const today = dayjs();
    return date.diff(today, "day") >= 1;
  }

  // 記録登録系統
  recordInput: {
    recordDate: string;
    record: number;
    reflection: string;
    description: string;
  } = {
    recordDate: dayjs().format("YYYY-MM-DD"),
    record: 0,
    reflection: "",
    description: ""
  };
  recordEntered = true;
  recordReflectionEntered = false;
  recordDescriptionEntered = false;
  validRecordInput = true;
  validRecordReflectionInput = false;
  validRecordDescriptionInput = false;
  get validReflectionInput() {
    return (
      this.validRecordInput &&
      this.validRecordReflectionInput &&
      this.validRecordDescriptionInput
    );
  }
  openRecordInput() {
    this.status = "recordInput";
  }
  clearRecordInput() {
    this.recordInput = {
      recordDate: dayjs().format("YYYY-MM-DD"),
      record: 0,
      reflection: "",
      description: ""
    };
    this.recordEntered = true;
    this.recordReflectionEntered = false;
    this.recordDescriptionEntered = false;
    this.validRecordInput = true;
    this.validRecordReflectionInput = false;
    this.validRecordDescriptionInput = false;
  }
  async registerRecord() {
    if (!this.unit) return;
    if (!this.validReflectionInput) return;
    if (this.recordInput.reflection.length === 0) {
      alert("振り返りが入力されていません。");
      return;
    }
    if (this.recordInput.description.length === 0) {
      alert("自由入力が入力されていません。");
      return;
    }
    if (this.isFutureDate(this.recordInput.recordDate)) {
      alert("未来の日付は登録できません。");
      return;
    }
    try {
      store.commit("SET_LOADING", true);
      store.commit("SET_LOAD_TEXT", "登録中...");
      this.recordInput.recordDate = this.generateReflectionUid(
        this.reflections,
        this.recordInput.recordDate
      );
      const newRef = this.unit.ref
        .collection(reflectionCollectionKey)
        .doc(this.recordInput.recordDate);
      await newRef.set({
        uid: newRef.id,
        record: this.recordInput.record,
        reflection: this.recordInput.reflection,
        description: this.recordInput.description,
        createdAt: dayjs(this.recordInput.recordDate)
          .startOf("day")
          .unix(),
        updatedAt: dayjs().unix()
      });
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
      this.$router.go(0);
    } catch (e) {
      alert("記録の登録に失敗しました。");
      console.error(e);
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
    }
  }

  //記録編集系統
  reflectionToEdit: Reflection | null = null;
  eRecordInput: {
    recordDate: string;
    record: number;
    reflection: string;
    description: string;
    createdAt: number;
  } = {
    recordDate: dayjs().format("YYYY-MM-DD"),
    record: 0,
    reflection: "",
    description: "",
    createdAt: 0
  };
  eRecordEntered = true;
  eRecordReflectionEntered = true;
  eRecordDescriptionEntered = true;
  eValidRecordInput = true;
  eValidRecordReflectionInput = true;
  eValidRecordDescriptionInput = true;
  get eValidReflectionInput() {
    return (
      this.eValidRecordInput &&
      this.eValidRecordReflectionInput &&
      this.eValidRecordDescriptionInput
    );
  }
  openRecordEditInput(reflectionId: string) {
    if (!reflectionId) return;
    if (!this.reflections.length) return;
    const targetReflection = this.reflections.find(
      r => r.data.uid === reflectionId
    );
    if (!targetReflection) {
      alert(`${reflectionId}の記録が見つかりません。`);
      return;
    }
    this.reflectionToEdit = { ...targetReflection };
    const { data } = this.reflectionToEdit;
    this.eRecordInput = {
      recordDate: this.normalizeUid(data.uid),
      record: data.record,
      reflection: data.reflection,
      description: data.description,
      createdAt: data.createdAt
    };
    this.status = "recordEdit";
  }
  clearRecordEditInput() {
    this.reflectionToEdit = null;
    this.eRecordInput = {
      recordDate: dayjs().format("YYYY-MM-DD"),
      record: 0,
      reflection: "",
      description: "",
      createdAt: 0
    };
    this.eRecordEntered = true;
    this.eRecordReflectionEntered = true;
    this.eRecordDescriptionEntered = true;
    this.eValidRecordInput = true;
    this.eValidRecordReflectionInput = true;
    this.eValidRecordDescriptionInput = true;
  }
  async updateRecord() {
    if (!this.unit) return;
    if (!this.reflectionToEdit) return;
    if (!this.eValidReflectionInput) return;

    if (this.eRecordInput.reflection.length === 0) {
      alert("振り返りが入力されていません。");
      return;
    }
    if (this.eRecordInput.description.length === 0) {
      alert("自由入力が入力されていません。");
      return;
    }
    if (this.isFutureDate(this.eRecordInput.recordDate)) {
      alert("未来の日付は登録できません。");
      return;
    }
    try {
      store.commit("SET_LOADING", true);
      store.commit("SET_LOAD_TEXT", "更新中...");

      const _date = this.normalizeUid(this.reflectionToEdit.data.uid);
      if (_date !== this.eRecordInput.recordDate) {
        this.eRecordInput.recordDate = this.generateReflectionUid(
          this.reflections,
          this.eRecordInput.recordDate
        );
        // 日付に変更がある場合は、新しく作って、今のを削除する
        const newRef = this.unit.ref
          .collection(reflectionCollectionKey)
          .doc(this.eRecordInput.recordDate);
        await Promise.all([
          newRef.set({
            uid: newRef.id,
            record: this.eRecordInput.record,
            reflection: this.eRecordInput.reflection,
            description: this.eRecordInput.description,
            createdAt: this.eRecordInput.createdAt,
            updatedAt: dayjs().unix()
          }),
          this.reflectionToEdit.ref.delete()
        ]);
      } else {
        await this.reflectionToEdit.ref.update({
          record: this.eRecordInput.record,
          reflection: this.eRecordInput.reflection,
          description: this.eRecordInput.description,
          updatedAt: dayjs().unix()
        });
      }

      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
      this.$router.go(0);
    } catch (e) {
      alert("記録の更新に失敗しました。");
      console.error(e);
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
    }
  }

  async deleteRecord() {
    if (!this.unit) return;
    if (!this.reflectionToEdit) return;
    if (!window.confirm("この振り返りを削除しても良いですか？")) return;
    try {
      store.commit("SET_LOADING", true);
      store.commit("SET_LOAD_TEXT", "削除中...");
      await this.reflectionToEdit.ref.delete();
    } catch (e) {
      alert("削除に失敗しました。");
      console.error(e);
    } finally {
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
      this.$router.go(0);
    }
  }

  // 振り返り登録系統
  handleUnitReflectionInputs() {
    if (!this.unit) return;
    // 将来的に処理の内容を切り替える必要があるかもしれないので分岐を分けておく
    if (this.unit.data.selfReflection) {
      this.openUnitReflectionInput();
    } else {
      this.openUnitReflectionInput();
    }
  }
  unitReflection = "";
  validUnitReflection = false;
  openUnitReflectionInput() {
    this.unitReflection =
      this.unit && this.unit.data.selfReflection
        ? this.unit.data.selfReflection
        : "";
    this.validUnitReflection = this.unitReflection.length > 0 ? true : false;
    this.status = "unitReflectionInput";
  }
  clearUnitReflectionInput() {
    this.unitReflection = "";
    this.validUnitReflection = false;
    this.status = "display";
  }
  async registerUnitReflection() {
    if (!this.unit) return;
    if (!this.validUnitReflection) return;
    if (!this.unit.data.selfReflection && this.unitReflection.length === 0) {
      alert("振り返りが入力されていません。");
      return;
    }
    const text = this.unit.data.selfReflection ? "更新" : "登録";
    try {
      store.commit("SET_LOADING", true);
      store.commit("SET_LOAD_TEXT", `振り返り${text}中...`);
      await this.unit.ref.update({ selfReflection: this.unitReflection });
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
      this.$router.go(0);
    } catch (e) {
      alert(`振り返りの${text}に失敗しました。`);
      console.error(e);
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
    }
  }

  //AI振り返り系統
  async getGptMessage(): Promise<string> {
    if (!this.unit) throw new Error("単元がありません。");
    if (this.reflections.length === 0)
      throw new Error("振り返りデータがありません。");

    const prompt = "以下のデータをもとにポジティブな評価をしてください。";
    let result = "";
    result = `単元名 : ${this.unit.data.name}` + "\n";
    result =
      result +
      `記録の遷移 :
       ${this.reflections
         .map(
           _ =>
             dayjs.unix(this.getUnixFromUid(_.data.uid)).format("MM月DD日") +
             " : " +
             String(_.data.record)
         )
         .join("\n")}` +
      "\n";
    result = result.replace(/^\s+/gm, "");

    const call = firebase
      .app()
      .functions("asia-northeast1")
      .httpsCallable("get_message_generated_by_chatgpt");
    const res = await call({ prompt, messageText: result });
    return res.data.message as string;
  }

  async updateAiReflection(option: { actionType: "generate" | "delete" }) {
    if (!this.unit) return;
    if (option.actionType !== "generate" && option.actionType !== "delete")
      return;
    const isGenerating = option.actionType === "generate";
    const text = isGenerating ? "作成" : "削除";
    try {
      store.commit("SET_LOADING", true);
      store.commit(
        "SET_LOAD_TEXT",
        `AI振り返り${text}中...(このままお待ちください)`
      );
      const content = isGenerating ? await this.getGptMessage() : "";
      await this.unit.ref.update({ aiReflection: content });
      this.$emit("aiReflectionUpdate", { unitId: this.unit.data.uid, content });
    } catch (e) {
      alert(
        `AI振り返りの${text}に失敗しました。` + "\n" + e.message ??
          "不明なエラー"
      );
      console.error(e);
    } finally {
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
    }
  }

  generateAiReflection() {
    if (!this.unit) return;
    if (!this.unit.data.selfReflection) {
      alert(
        "AIの振り返りを利用するには、単元の自身の振り返りを記入してください。"
      );
      return;
    }
    if (this.unit.data.aiReflection) {
      const res = window.confirm(
        "現在登録されている振り返りが上書きされますが、よろしいですか？"
      );
      if (!res) return;
    }

    this.updateAiReflection({ actionType: "generate" });
  }

  deleteAiReflection() {
    const res = window.confirm("AI振り返りを削除してもよろしいですか？");
    if (!res) return;
    this.updateAiReflection({ actionType: "delete" });
  }

  // ジェネラル系統
  generateReflectionUid(reflections: Reflection[], date: string): string {
    /*
    dateは、"2024-12-01"のような形で渡ってくる
    各uidは、"2024-12-01", "2024-12-01-1", "2024-12-01-2"のように定義するものとする
    */
    if (!reflections.length) return date;
    const uidSet = new Set(reflections.map(r => r.data.uid));
    if (!uidSet.has(date)) return date;

    let i = 1;
    while (uidSet.has(`${date}-${i}`)) i++;
    return `${date}-${i}`;
  }

  normalizeUid(date: string): string {
    // "2024-12-22-1"のようなデータが渡ってくる前提
    const parts = date.split("-").map(p => String(p));
    if (parts.length === 3) {
      return date;
    } else if (parts.length === 4) {
      return `${parts[0]}-${parts[1]}-${parts[2]}`;
    } else {
      throw new Error(`Invalid date format -- ${date}`);
    }
  }

  getUnixFromUid(date: string): number {
    // "2024-12-22-1"のようなデータが渡ってくる前提
    if (!date) return 0;
    const _date = (() => {
      try {
        return this.normalizeUid(date);
      } catch (_) {
        return "";
      }
    })();
    if (!_date) return 0;

    return dayjs(_date)
      .startOf("day")
      .unix();
  }

  sortReflectionsByUid(reflections: Reflection[], option = { asc: true }) {
    if (!reflections || !Array.isArray(reflections) || !reflections.length) {
      return [];
    }
    const { asc } = option;
    const _reflections = [...reflections];
    return _reflections.sort((a, b) => {
      const diff =
        this.getUnixFromUid(a.data.uid) - this.getUnixFromUid(b.data.uid);
      if (diff !== 0) return asc ? diff : diff * -1;
      return a.data.updatedAt - b.data.updatedAt;
    });
  }

  async created(): Promise<void> {
    if (!this.student) {
      alert("生徒情報が取得できません。");
      this.$emit("close");
    }
    if (!this.unit) {
      alert("単元情報が取得できません。");
      this.$emit("close");
    }
    try {
      store.commit("SET_LOADING", true);
      store.commit("SET_LOAD_TEXT", "情報取得中...");
      const _unit = { ...this.unit } as Unit;
      this.unitName = _unit.data.name;
      this.graphTitle = _unit.data.graphTitle || "数値(記録)";
      this.secondRowTitle = _unit.data.secondRowTitle || "授業ごとの振り返り";
      this.thirdRowTitle = _unit.data.thirdRowTitle || "自由入力";

      const _reflections = await this.getReflections();
      this.reflections = this.sortReflectionsByUid(_reflections);
      console.log(this.reflections);

      if (this.reflections.length > 0) {
        const records = this.reflections.map(_ => +_.data.record);
        if (this.reflections.length > 1) {
          this.min = Math.min(...records) - 1;
          this.max = Math.max(...records) + 1;
        } else {
          const record = +this.reflections[0].data.record;
          this.min = record < 0 ? record - 1 : 0;
          this.max = record + 5;
        }
      }

      this.labels =
        this.reflections.length > 0
          ? this.reflections.map(_ =>
              dayjs.unix(this.getUnixFromUid(_.data.uid)).format("MM-DD")
            )
          : [];
      this.datasets =
        this.reflections.length > 0
          ? [
              {
                label: this.unitName,
                data: this.reflections.map(_ => _.data.record),
                borderColor: "rgb(75, 192, 192)",
                borderWidth: 2,
                tension: 0.1
              }
            ]
          : [];

      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
    } catch (e) {
      console.error(e);
      alert("情報の取得に失敗しました。");
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
      this.$emit("close");
    }
  }
}
